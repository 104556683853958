import { useEffect, useState, useMemo } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { isValidAddress } from "@xmtp/react-sdk";

import useAPI from "utils/api";
import { useSnackbar } from "context";
import SuiInput from "components/soft-ui/SuiInput";
import SuiButton from "components/soft-ui/SuiButton";
import SuiBox from "components/soft-ui/SuiBox";
import SuiTypography from "components/soft-ui/SuiTypography";
import MediaFileUpload from "components/MediaFileUpload";

const initialFileInfo = {
  files: [],
  filePreviews: [],
};

const initialEducationInfo = {
  title: "",
  description: "",
  walletAddress: "",
};

export default function AddEducationDialog({ open, onClose, educationData: eData, callback }) {
  const api = useAPI();
  const { showSnackbar } = useSnackbar();
  const [educationInfo, setEducationInfo] = useState(initialEducationInfo);
  const [fileInfo, setFileInfo] = useState(initialFileInfo);
  const [errMessage, setErrMessage] = useState("");
  const [saving, setSaving] = useState(false);

  const isEdit = useMemo(() => !!eData, [eData]);

  const handleSave = async () => {
    setErrMessage("");

    if (!fileInfo.files.length && !fileInfo.filePreviews.length) {
      setErrMessage("Please select education image");
      return;
    } else if (!educationInfo.title) {
      setErrMessage("Please input education title");
      return;
    }
    // } else if (!educationInfo.walletAddress) {
    //   setErrMessage("Please input wallet address");
    //   return;
    // } else if (!isValidAddress(educationInfo.walletAddress)) {
    //   setErrMessage("Please input valid wallet address");
    //   return;
    // }

    setSaving(true);

    const educationFormData = {
      title: educationInfo.title,
      description: educationInfo.description,
      wallet_address: educationInfo.walletAddress,
    };

    if (isEdit) {
      educationFormData.educationId = eData.id;
    }

    if (!!fileInfo.files.length) {
      const fileName = fileInfo.files[0].name;
      const fileType = fileInfo.files[0].type;

      let urls;
      try {
        urls = await api("generate_presigned_urls", {
          category: "educations",
          files: [{ name: fileName, type: fileType }],
        });
      } catch (err) {
        showSnackbar("Error", "Failed to generate presigned url", "error");
        setSaving(false);
        return;
      }

      try {
        await axios.put(urls[0], fileInfo.files[0], {
          headers: {
            "Content-Type": fileType,
            "X-Amz-Acl": "public-read",
          },
        });
      } catch (err) {
        showSnackbar("Error", "Failed to upload file", "error");
        setSaving(false);
        return;
      }

      educationFormData.media_url = urls[0].split("?")[0];
    }

    try {
      await api("save_education", educationFormData);
      onClose();
      callback();

      setEducationInfo(initialEducationInfo);
      setFileInfo(initialFileInfo);

      const message = isEdit ? "Updated successfully" : "Added successfully";
      showSnackbar("Success", message, "info");
    } catch (err) {
      showSnackbar("Error", "Failed to save education", "error");
    }

    setSaving(false);
  };

  useEffect(() => {
    if (!isEdit) return;

    const updatedEducationInfo = {
      ...initialEducationInfo,
      title: eData.title,
      description: eData.description,
      walletAddress: eData.wallet_address,
    };

    const updatedFileInfo = {
      ...initialFileInfo,
      filePreviews: [eData.media_url],
    };

    setEducationInfo(updatedEducationInfo);
    setFileInfo(updatedFileInfo);
  }, [isEdit, eData]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{isEdit ? "Edit" : "Add a new"} Education</DialogTitle>
      <DialogContent sx={{ minWidth: { sm: "400px" } }}>
        {errMessage && (
          <SuiTypography color="error" fontSize="12px" mb={1}>
            {errMessage}
          </SuiTypography>
        )}
        <SuiBox mb={2}>
          <DialogContentText>Video</DialogContentText>
          <MediaFileUpload filesInfo={fileInfo} fileType="video" handleUpdateFiles={setFileInfo} />
        </SuiBox>
        <SuiBox mb={2}>
          <DialogContentText>Title</DialogContentText>
          <SuiInput
            value={educationInfo.title}
            error={!educationInfo.title}
            onChange={(e) => setEducationInfo({ ...educationInfo, title: e.target.value })}
          />
        </SuiBox>
        <SuiBox mb={2}>
          <DialogContentText>Description</DialogContentText>
          <SuiInput
            value={educationInfo.description}
            multiline
            rows={4}
            onChange={(e) => setEducationInfo({ ...educationInfo, description: e.target.value })}
          />
        </SuiBox>
        <SuiBox>
          <DialogContentText>Wallet Address</DialogContentText>
          <SuiInput
            value={educationInfo.walletAddress}
            // error={!educationInfo.walletAddress || !isValidAddress(educationInfo.walletAddress)}
            onChange={(e) => setEducationInfo({ ...educationInfo, walletAddress: e.target.value })}
          />
        </SuiBox>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <SuiButton
          color="primary"
          circular
          sx={{ width: "105px" }}
          disabled={saving}
          onClick={handleSave}
        >
          {saving ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Save"}
        </SuiButton>
        <SuiButton variant="outlined" color="primary" circular onClick={onClose}>
          Cancel
        </SuiButton>
      </DialogActions>
    </Dialog>
  );
}
